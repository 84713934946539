import SidePanelPreview from './sidePanelPreview/SidePanelPreview';
import styles from './QuestionnairePreview.module.css';
import { useSelector, useDispatch } from 'react-redux';
import ModuleContent from '../moduleContent/ModuleContent';
import QuestionPreview from '../questionPreview/QuestionPreview';
import { useEffect, useRef, useState } from 'react';
import { GetPreviewData, isBoosterWave } from '../../../utility/utility';
import { useParams } from 'react-router-dom';
import Button from '../../ui/buttons/Button';

import { generateWordDocument } from './QuestionnairePreview.util';

import {
  GetCustomQuestions,
  GetWave,
  fetchModulesList,
  getProjectIds,
  updateCrumbs,
} from '../../../store/actions';
import { getAllProjectModules } from '../../../store/actions/modules';

import { downloadProjectXLSX, downloadWaveXLSX } from '../../../utility/downloadXlsx'
/**
 * @class QuestionnairePreview
 *
 *
 * @description This component allows the user to preview their module selection ( all selected modules, local questions, and the length of interview ), as well as download the preview as a .docx file.
 * For more information as to how the .docx is generated @see './QuestionnairePreview.util'.
 *
 * The **QuestionnairePreview** component is used in the **App** component.
 *
 * @returns {JSX}
 */
const QuestionnairePreview = () => {
  const dispatch = useDispatch();

  const linkParams = useParams();

  const isDownloadAll =
    linkParams.hasOwnProperty('modules') &&
    linkParams?.modules === 'all-modules';
  const { wave } = useSelector((store) => store.wave);
  const selectedModules = useSelector((state) =>
    GetPreviewData(state.modules.modules, 'sequence', 'selected')
  );
  const isLoadingModules = useSelector((state) => state.modules.loading);
  const isLoadingCustQ = useSelector(
    (state) => state.customQuestions.isLoading
  );

  const tempCustQ = useSelector((state) =>
    GetPreviewData(state.customQuestions.customQuestions, 'sequence')
  );

  let customQuestions;
  if (linkParams?.modules === 'all-modules') {
    customQuestions = [];
  } else {
    customQuestions = tempCustQ;
  }

  const { countries } = useSelector((state) => state.wave);
  const ref = useRef({});
  const scrollOps = useRef({ modules: null, localQuestions: null });
  const isMenuItemClickedRef = useRef();

  const [activeMenu, setActiveMenu] = useState(null);
  const [isMenuItemClicked, setMenuItemClicked] = useState(false);

  const [
    isCountrySpecificQuestionsDetected,
    setCountrySpecificQuestionsDetected,
  ] = useState(false);
  const projectModulesForDownload = useSelector((state) =>
    GetPreviewData(state.modules.projectModules, 'sequence')
  );
  const projects = useSelector((state) => state.app.projects);
  const currentCountryId = useRef();

  const noContent = useSelector(
    (state) => state.modules.error === 'No Content'
  );

  useEffect(() => {
    if (Object.keys(countries).length > 0) {
      currentCountryId.current = countries.find(
        (country) => country.name === linkParams.country
      ).countryId;
      }
  }, [countries, linkParams.country]);

  useEffect(() => {
    if (linkParams.hasOwnProperty('projectId') && currentCountryId.current) {
      dispatch(
        getAllProjectModules(linkParams.projectId, currentCountryId.current)
      );
      }
  }, [linkParams.projectId, dispatch, linkParams]);

  useEffect(() => {
    dispatch(getProjectIds());
  }, [dispatch]);

  isMenuItemClickedRef.current = isMenuItemClicked;
  const modulesForDownload = isDownloadAll
    ? projectModulesForDownload
    : selectedModules;
  const [fileName, setFileName] = useState();

  useEffect(() => {
    if (!wave || !projects) return;
    if (isDownloadAll) {
      setFileName(
        `${linkParams.country} - ${projects[linkParams.projectId].name}`
      );
    } else {
      setFileName(wave?.name);
      }
  }, [wave, projects, isDownloadAll, linkParams.projectId, linkParams.country]);

  useEffect(() => {
    if (!wave || !projects) return;
    if (isDownloadAll) {
      setFileName(
        `${linkParams.country} - ${projects[linkParams.projectId].name}`
      );
    } else {
      setFileName(wave?.name);
    }
  }, [wave, projects, isDownloadAll, linkParams.projectId, linkParams.country]);
  /**
   * @function
   * @memberof QuestionnairePreview
   * @param {String} id String containing `module_${index of module}'
   *
   * @description Updates the styling of the country clicked in the 'SidePanelPreview' component to reflect that it is the active module, and scrolls the module into view.
   */
  const handleClick = (id) => {
    console.log(id);
    setMenuItemClicked(true);
    setActiveMenu(id);
    ref.current[id]?.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => {
      setMenuItemClicked(false);
    }, 1000);
  };

  /**
   * @function
   * @memberof QuestionnairePreview
   * @param {Number} index
   *
   * @@returns {String} `module_${index}`;
   */
  const setModuleId = (index) => {
    return `module_${index}`;
  };

  /**
   * @function
   * @memberof QuestionnairePreview
   * @param {Number} index
   *
   * @@returns {String} `localQuestion_${index}`;
   */
  const setLocalQuestionId = (index) => {
    return `localQuestion_${index}`;
  };

  /**
@method useEffect - 1st instance
   * @memberof QuestionnairePreview
   * @param {String} wave.name The name of the wave that is being previewed.
   *
   * @description  Once the component mounts and 'wave.name' is not falsy the effect dispatches 'updateCrumbs', to include the country and wave name.
   *
   * As 'wave.name' does not change, this effect is only triggered once, when the component mounts, and 'wave.name' is not falsy.
   */
  useEffect(() => {
    if (wave?.name) {
      dispatch(
        updateCrumbs([
          { text: 'Waves', link: '/' },
          { text: linkParams.country, link: `waves/${linkParams.country}` },
          { text: wave.name + ' preview', link: null },
        ])
      );
    }
  }, [wave.name, dispatch, linkParams.country]);

  //adding breadcrumbs for project level (all modules) preview
  useEffect(() => {
    if (isDownloadAll && projects) {
      let projectExists = projects.hasOwnProperty(linkParams.projectId);
      dispatch(
        updateCrumbs([
          { text: 'Waves', link: '/' },
          { text: linkParams.country, link: `waves/${linkParams.country}` },
          {
            text: projectExists
              ? projects[linkParams.projectId].name + ' - preview'
              : '',
            link: null,
          },
        ])
      );
    }
  }, [isDownloadAll, projects,dispatch, linkParams.projectId, linkParams.country]);
  /**
@method useEffect - 2nd instance
   * @memberof QuestionnairePreview
   * @param {Array} countries Array containing all countries that the user has access to.

   * @description  When 'countries' is not falsy the effect checks the country corresponding to the wave being previewed to see if it contains country specific questions, and based on this it sets the state variable 'countrySpecificQuestionsDetected' to the appropriate boolean value.
   *
   * This effect is triggered when 'countries' updates, however this only happens once, after the component first mounts and 'countries' is selected from the store.
   */
  useEffect(() => {
    if (countries?.length > 0) {
      const selectedCountryDetails = countries.find(
        (x) => x.name === linkParams.country
      );
      const projectID =
        selectedCountryDetails.waves.find(
          (wave) => wave.waveId === linkParams.waveId
        )?.projectId || parseInt(linkParams.projectId);
      const hasCountrySpecificQuestions =
        selectedCountryDetails.projectStatusForCountrySpecificData.find(
          (proj) => proj.projectId === projectID
        )?.hasCountrySpecificQuestions || false;
      selectedCountryDetails && hasCountrySpecificQuestions
        ? setCountrySpecificQuestionsDetected(true)
        : setCountrySpecificQuestionsDetected(false);
      console.log(
        'setting country specifics......',
        selectedCountryDetails,
        hasCountrySpecificQuestions
      );
      }
  }, [countries, linkParams.projectId, linkParams.country, linkParams.waveId]);

  /**
@method useEffect - 3rd instance
   * @memberof QuestionnairePreview
   *
   * @description  When the component mounts the effect dispatches 'GetCustomQuestions', and 'GetWave' to get the data, including the custom questions, specific to this wave.
   */
  useEffect(() => {
    if (linkParams.hasOwnProperty('waveId')) {
      dispatch(GetCustomQuestions(linkParams.waveId));
      dispatch(GetWave(linkParams.waveId));
      }
  }, [dispatch, linkParams]);

  /**
@method useEffect - 4th instance
   * @memberof QuestionnairePreview
   * @param {Object} wave All data pertaining to the current wave.

   * @description  When 'wave' is not falsy the effect dispatches 'fetchModulesList' to get the modules that were selected for this wave.
   *
   * This effect is triggered when 'wave' updates, however this only happens once, after the component first mounts and 'wave' is selected from the store.
   */
  useEffect(() => {
    if (Object.keys(wave).length > 0) {
      dispatch(
        fetchModulesList(
          wave.projectId,
          wave.waveId,
          wave.countryId,
          isBoosterWave(wave)
        )
      );
    }
  }, [wave,dispatch]);

  /**
@method useEffect - 5th instance
   * @memberof QuestionnairePreview
   * @param {string} ref.current String containing either`module_${index of module}' or `localQuestion${index of module}', depending on which one is currently on the screen ( according to IntersectionObserver )

   * @description  Updates the currently active menu in the 'SidePanelPreview' component based on which one is being shown on the screen at any given point.
   *
   * This effect is executed every time 'ref.current' is updated, to accurately reflect the module/local question that is being viewed at any given point.
   */
  useEffect(() => {
    let observer;

    if (Object.keys(ref?.current).length) {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !isMenuItemClickedRef.current) {
              setActiveMenu(entry.target.id);
            }
          });
        },
        {
          threshold: 0.02, // seems to be causing a bug in the sidebar, decreased its value from 10% to 2% and it seems to fix the issue.
        }
      );

      Object.values(ref.current).forEach((element) => {
        observer?.observe(element);
      });
    }
    return () => observer?.disconnect();
  }, []);

  /**
@method useEffect - 6th instance
   * @memberof QuestionnairePreview
   *
   * @description When the component mounts the effect scrolls the window to the top. This effect is required to improve the user experience because sometimes when the component mounts the window is scrolled down and the Breadcrumbs component is not visible.
   */
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, []);

  const handleGenerateWordFile = () => {
    console.log(
      modulesForDownload,
      fileName,
      isCountrySpecificQuestionsDetected,
      'stuff for the download'
    );
    generateWordDocument(
      modulesForDownload,
      fileName,
      customQuestions,
      isCountrySpecificQuestionsDetected,
      isDownloadAll
    );
  };
   const handleDownload = () => {
       if(isDownloadAll){
        dispatch(downloadProjectXLSX(linkParams.projectId, currentCountryId.current, fileName));
       } else {
        dispatch(downloadWaveXLSX(wave.waveId, fileName));
       }     
   };
  return (
    <div data-testid="id-Questionnaire preview" className={styles.Page}>
      <SidePanelPreview
        handleClick={handleClick}
        activeMenu={activeMenu}
        setModuleId={setModuleId}
        setLocalQuestionId={setLocalQuestionId}
        scrollOps={scrollOps}
        downloadAll={isDownloadAll}
      ></SidePanelPreview>
      <div className={styles.bodyText}>
        {(!isDownloadAll && (isLoadingModules || isLoadingCustQ)) ||
        (isDownloadAll && isLoadingModules) ? (
          <div className={styles.loading}>Loading...</div>
        ) : (
          <>
            <div data-testid="id-ModuleContents">
              <div role="document" className={`${styles.headerPadding}`}>
                <h3 className={`${styles.label} ${styles.labelHeader}`}>
                  <b ref={(el) => (scrollOps.current.modules = el)}>Modules</b>
                </h3>
              </div>
              {!isCountrySpecificQuestionsDetected && !noContent && (
                <p
                  className={`${styles.headers} ${styles.contentBold} ${styles.notificationMsg} printHidden`}
                >
                  No country specific data detected for this country
                </p>
              )}
              {selectedModules?.length > 0 && tempCustQ.length > 0}
              {noContent && (
                <div className={styles.noDataErrorMessage}>
                  There are no modules available for this project.
                </div>
              )}
              <div className={`${styles.downloadbtnContainer} printHidden`}>
                <Button
                  type="primary"
                  handleOnClick={handleGenerateWordFile}
                  disabled={
                    noContent ||
                    (isDownloadAll &&
                      !projects?.hasOwnProperty(linkParams.projectId)) ||
                    false
                  }
                >
                  Download preview
                </Button>
                <Button type="primary"
                  handleOnClick={handleDownload}
                  disabled={
                    noContent ||
                    (isDownloadAll &&
                      !projects?.hasOwnProperty(linkParams.projectId)) ||
                    false
                  }
                  >
                  Download Excel File
                </Button>
              </div>
              {modulesForDownload &&
                modulesForDownload.map((module, index) => {
                  const id = setModuleId(index);
                  return (
                    <div ref={(el) => (ref.current[id] = el)} id={id} key={id}>
                      <ModuleContent
                        moduleId={module.surveyModuleId}
                        isPreview={true}
                        downloadAll={isDownloadAll}
                      />
                    </div>
                  );
                })}
            </div>

            {customQuestions.length > 0 && (
              <>
                <div className={`${styles.headerPadding}`}>
                  <h3 className={`${styles.label} ${styles.labelHeader}`}>
                    <b
                      styles={{ scrollMarginTop: '2000px' }}
                      ref={(el) => (scrollOps.current.localQuestions = el)}
                    >
                      Local Questions
                    </b>
                  </h3>
                </div>
                <div
                  data-testid="id-QuestionPreviews"
                  className={styles.localQuestionList}
                >
                  <ol style={{ paddingLeft: '50px' }}>
                    {customQuestions.map((customQuestion, index) => {
                      const id = setLocalQuestionId(index);
                      return (
                        <li
                          ref={(el) => (ref.current[id] = el)}
                          id={id}
                          key={id}
                        >
                          <QuestionPreview
                            customQuestionId={customQuestion.customQuestionId}
                          ></QuestionPreview>
                        </li>
                      );
                    })}
                  </ol>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default QuestionnairePreview;
