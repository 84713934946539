import React, { useEffect, useState, useRef } from 'react';
import Checkbox from '../../ui/checkbox/Checkbox';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchModulesList,
  updateModuleSelection,
  updateMultipleModuleSelection,
  updateGroupValidCheck,
  saveModuleSelection,
  GetCustomQuestions,
  DeleteCustomQuestion,
  CreateCustomQuestion,
  GetWave,
} from '../../../store/actions';
import Button from '../../ui/buttons/Button';
import Logo from '../../ui/logo/Logo';
import QuestionTypeSelection from '../../ui/QuestionTypeSelection/QuestionTypeSelection';
import ComplexButton from '../../ui/buttons/ComplexButton';
import styles from './SidePanel.module.css';
import LengthOfInterview from '../../ui/text/LengthOfInterview';
import ModalDialog from '../../ui/modal/Modal';
import * as appMessages from '../../../utility/messages';
import {
  showToaster,
  checkUXPrivileges,
  isBoosterWave,
  handleMandatoryIcon,
  shouldShowTooltip,
  setBorderStyle,
  customQuestionloiContributionInMinutes,
  checkMandatoryChoiceGroup,
  getMessageWithReplacedKeyValue,
  GetFormattedLocalQuestionName,
} from '../../../utility/utility';
import { Tooltip } from '../../ui/Tooltip/Tooltip';
import { calcloi } from '../../../utility/calcloi';
import { handleMandatoryMessage } from '../../../utility/messages';

import ModuleGroupPopup from '../../ui/moduleGroupPopup/ModuleGroupPopup';

/**
 * @class SidePanel
 * @param {Number} waveId Id of the wave on which the module selection process is being viewed.
 * @param {Number} projectId Id corresponding to the type of project assigned to this wave (Project types include Consumer Tracker 2023, Incidence Tracker 2023, etc.).
 * @param {Function} clickHandler Function that is called when either a module or a local question is called. The function is used to render the contents of the clicked module/local question inside the 'ModuleContent' component.
 * @param {Function} selectedPageTypeHandler Function that is called on Modules/Local question tab click event on side panel page.
 * @param {Number} maxCustomQ Maximum number of local questions than can be added to this wave.
 * @param {Number} maxLoi Maximum length of interview that each wave can have. The length is the sum of the lengths of each module and local question selected.
 * @param {Boolean} isReadOnly Boolean determining if the module selection can be edited.
 * @param {String} moduleSelectionStatus Status of this wave's Module Selection process.
 *
 * @description The component handles the selection of modules and local questions. It ensures that the user's selection is in line with the rules for the wave's module selection, and handles multiple rules ( 'mandatoryonceperyear', 'mandatorythiswave', 'mandatoryfirstwave', 'mandatorylastwave' 'exclusive', 'dependency', 'mandatory' etc. ). The component also makes sure that the user's module selection can't exceed the length of interview limit ( unless 'OverwriteLoiLimit' is included in the user's uxPrivileges ).
 *
 * @returns {JSX}
 */
const SidePanel = ({
  waveId,
  projectId,
  clickHandler,
  selectedPageTypeHandler,
  maxCustomQ,
  maxLoi,
  isReadOnly,
  moduleSelectionStatus,
  countryId,
}) => {
  const modules = useSelector((state) => state.modules.modules);
  const order = useSelector((state) => state.modules.orderedList);
  const loading = useSelector((state) => state.modules.loading);
  const groups = useSelector((state) => state.modules.groups);
  const changesToSave = useSelector((state) => state.modules.changesToSave);
  const [activeModule, updateActiveModule] = useState(-1);
  const customQuestions = useSelector(
    (state) => state.customQuestions.customQuestions
  );
  const loadingCustQ = useSelector((state) => state.customQuestions.isLoading);
  const { wave } = useSelector((store) => store.wave);
  const { uxPrivileges } = useSelector((state) => state.app.user.user.role);
  const mandatoryChoiceGroup = useSelector((state) => {
    return Object.values(state.modules.groups).find((group) =>
      checkMandatoryChoiceGroup(group)
    );
  });

  const { countries } = useSelector((state) => state.wave);
  const dispatch = useDispatch();
  const [questionType, setQuestionType] = useState('1');
  const [mandatoryIconErr, setMandatoryIconErr] = useState(false);
  const [focusedQuestion, setFocusedQuestion] = useState();  
  const [cascadingSelection, setCascadingSelection] = useState({
        lastChecked: null,
        autoselected: [],
    });
  const [dependencyChoiceCurrentGroup, setDependencyChoiceCurrentGroup] =
    useState({
      activeModuleId: null,
      activeModuleName: '',
      dependentModules: [],
      isRecursiveCheck: false,
    });
  const [moduleGroupData, setModuleGroupData] = useState({});
  const [savedModuleSelection, setSavedModuleSelection] = useState(false);
  const [isLoadingCustQ, setIsLoadingCustQ] = useState(loadingCustQ);
  const [showMaxLoiModal, setShowMaxLoiModal] = useState(false);

  const dependencyChoiceGroups = useRef();
  const cascadingSelectionGroups = useRef();
  const ref = useRef({});
  let mandatoryModuleName = useRef('');
  const [ISOCode, setCountryISOCode] = useState('');
  const [selectable, setSelectable] = useState({}); //the modules that will be selected through recursion, including the trigger module
  const [dependencyGroupsList, setDependencyGroupsList] = useState([]); //this will hold groups with details for the dependencyChoice rule, in order to show the modal

  const [forceRerender, setForceRerender] = useState(0);

  useEffect(() => {
    dispatch(GetCustomQuestions(waveId));
    dispatch(GetWave(waveId));
  }, [dispatch,waveId]);

  useEffect(() => {
    const countryData = Object.values(countries).find(
      (n) => n.countryId === wave.countryId
    );
    const countryISOCode = countryData?.isoCode ? countryData.isoCode : '';
    setCountryISOCode(countryISOCode);
  }, [countries, wave.countryId]);

  const messages = useSelector((state) => state.message.messages);

  // Ref used here so that the function is only executed again if the uxPrivileges change
  const canOverwriteLoiLimit = useRef(
    checkUXPrivileges(uxPrivileges, 'OverwriteLoiLimit')
  );

  useEffect(() => {
    if (dependencyGroupsList.length > 0) {
      //ONLY TAKING INTO ACCOUNT FIRST GROUP - ASSUMING ONLY ONE GROUP AT A TIME;
      //if case with multiple groups for a module comes up, this will have to be reworked
      let tempObj = dependencyGroupsList[0];
      setDependencyChoiceCurrentGroup(tempObj);
    }
  }, [dependencyGroupsList]);

    const recursiveModuleGroupCheck = (
        module,
        modulesToUpdate,
        modulesForToast,
        groupsForPopup,
        cascadingAutoselected
    ) => {
        const dependentSecondaryModules = [];

        Object.values(cascadingSelectionGroups.current).forEach((grp) => {
            if (grp.moduleIds.includes(module.moduleId)) {
                grp.moduleIds.forEach((id) => {
                    const moduleFromId = grp.modules.find((mod) => mod.moduleId === id);
                    if (
                        !dependentSecondaryModules.includes(id) &&
                        moduleFromId.sequence < module.sequence &&
                        !moduleFromId.checked
                    ) {
                        dependentSecondaryModules.push({
                            moduleId: id,
                            sequence: moduleFromId.sequence,
                            checked: moduleFromId.checked,
                            name: moduleFromId.name,
                        });
                        if (!modulesForToast.includes(moduleFromId.name)) {
                            modulesToUpdate.push({ id: id, checked: true });
                            modulesForToast.push(moduleFromId.name);
                        }
                        if (cascadingAutoselected.lastChecked != null) {
                            cascadingAutoselected.autoselected.push({
                                id: id,
                                checked: true,
                            });
                            console.log('is different than null', cascadingAutoselected);
                        }
                    }
                });
            }
        });

        Object.values(dependencyChoiceGroups.current).forEach((grp) => {
            if (grp.primaryModuleId === module.moduleId && grp.modules.length > 0) {
                const activeModName = grp.modules.find(
                    (mod) => mod.moduleId === module.moduleId
                ).name;
                const skipPopup =
                    grp.modules.find((mod) => mod.checked === true) || false;
                if (!skipPopup) {
                    groupsForPopup.push({
                        activeModuleId: module.moduleId,
                        activeModuleName: activeModName,
                        dependentModules: [...grp.modules],
                        isRecursiveCheck: true,
                    });
                    grp.modules.forEach((mod) => {
                        if (
                            mod.moduleId !== module.moduleId &&
                            !dependentSecondaryModules.find(
                                (el) => el.moduleId === mod.moduleId
                            )
                        ) {
                            dependentSecondaryModules.push({
                                moduleId: mod.moduleId,
                                sequence: mod.sequence,
                                checked: mod.checked,
                                name: mod.name,
                            });
                        }
                    });
                }
            }
        });

        if (dependentSecondaryModules.length > 0) {
            dependentSecondaryModules.forEach((mod) => {
                recursiveModuleGroupCheck(
                    mod,
                    modulesToUpdate,
                    modulesForToast,
                    groupsForPopup
                );
            });
        }
    };        
   
  const GetCustomNamePrefix = () => {
    return 'LQ'.concat('_',ISOCode);
  };

    const recursiveModuleGroupUncheck = (
        module,
        modulesForUpdate,
        modulesForToast
    ) => {
        const dependentSecondaryModules = [];

        Object.values(cascadingSelectionGroups.current).forEach((grp) => {
            if (grp.moduleIds.includes(module.moduleId)) {
                grp.moduleIds.forEach((id) => {
                    const moduleFromId = grp.modules.find((mod) => mod.moduleId === id);
                    if (
                        !dependentSecondaryModules.includes(id) &&
                        moduleFromId.sequence > module.sequence &&
                        moduleFromId.checked
                    ) {
                        dependentSecondaryModules.push({
                            moduleId: id,
                            sequence: moduleFromId.sequence,
                            checked: moduleFromId.checked,
                            name: moduleFromId.name,
                        });
                        if (!modulesForToast.includes(moduleFromId.name)) {
                            modulesForUpdate.push({ id: id, checked: false });
                            modulesForToast.push(moduleFromId.name);
                        }
                    }
                });
            }
        });

        Object.values(dependencyChoiceGroups.current).forEach((grp) => {
            if (
                grp.moduleIds.includes(module.moduleId) &&
                grp.primaryModuleId !== module.moduleId
            ) {
                const primaryModule = grp.modules.find(
                    (mod) => mod.moduleId === grp.primaryModuleId
                );
                const isPrimaryChecked = primaryModule.checked;
                const isSecondaryChecked = grp.modules.find(
                    (mod) =>
                        mod.moduleId !== primaryModule.moduleId &&
                        mod.moduleId !== module &&
                        mod.checked === true
                )?.checked;
                const primaryModuleName = primaryModule.name;

                if (isPrimaryChecked && !isSecondaryChecked) {
                    if (
                        !dependentSecondaryModules.find(
                            (mod) => mod.moduleId === primaryModule.moduleId
                        )
                    ) {
                        dependentSecondaryModules.push({ ...primaryModule });
                        modulesForToast.push(primaryModuleName);
                    }
                }
            }
        });

        if (dependentSecondaryModules.length > 0) {
            dependentSecondaryModules.forEach((module) => {
                recursiveModuleGroupUncheck(module, modulesForUpdate, modulesForToast);
            });
        }
    };    

  const handleUserInputRecursiveCheck = (selectedModules) => {
    const toastMessages = [];
    const message = [];
    const modulesForToast = [];
    const activeModuleName = modules[activeModule].name;
    selectedModules.forEach((mod) => {
      handleCheck(mod, true);
    });
    if (Object.keys(selectable).length > 0) {
      const tempObj = { ...selectable };
      selectedModules.forEach((id) => {
        tempObj.modules.push({ id: id, checked: true });
      });
      setSelectable({});
      setDependencyChoiceCurrentGroup({
        activeModuleId: null,
        activeModuleName: '',
        dependentModules: [],
        isRecursiveCheck: false,
      });
      selectable.modules.forEach((module) => {
        if (module.id !== activeModule) {
          modulesForToast.push(modules[module.id].name);
        }
      });
      message.push(
        appMessages.cascadingSelectionMessages(
          'select',
          modulesForToast,
          activeModuleName
        )
      );
      updateToastMessageArray(toastMessages, message);
      dispatch(updateMultipleModuleSelection(tempObj));
    }
    validateAndShowToast(toastMessages);
  };

  /**
   * @function
   * @memberof SidePanel
   * @param {Number} id Id of local question.
   * @param {Object} question Object containing all data of the custom question on which this function is called
   *
   * @description The function is used to update the contents of the 'ModuleContent' component as well as update the styling of the clicked local question to show that it was clicked.
   */
  const setCurrentCustomQuestion = (id, question) => {
    clickHandler('customQ', id, question);
    setFocusedQuestion(id);
  };

  /**
   * @function
   * @memberof SidePanel
   * @param {Number} id Id of local question.
   *
   * @description The function deletes the local question from the store and sets the contents of the 'ModuleContent' component to be blank.
   */
  const handleDeleteCustomQuestion = (id) => {
    dispatch(DeleteCustomQuestion(waveId, id));
    clickHandler('blankPage');
  };


    const handleComboGroupCheck = (module, checked) => {
        let toasterMessage = [];
        const cascadingGroupsModules = {};
        const activeModName = modules[module].name;
        const modulesForUpdate = { modules: [] };
        const modulesForToast = [];
        const recursiveCheckArray = [];
        const groupsForPopup = [];
        let isCascading = false;
        const cascadingAutoselected = { lastChecked: null, autoselected: [] };

        //handle cascadingSelection rule - get groups
        Object.values(cascadingSelectionGroups.current).forEach((grp) => {
            if (grp.moduleIds.includes(module)) {
                grp.moduleIds.forEach((id) => {
                    const moduleFromId = grp.modules.find(
                        (module) => module.moduleId === id
                    );
                    if (!cascadingGroupsModules.hasOwnProperty(id)) {
                        cascadingGroupsModules[id] = {
                            moduleId: id,
                            sequence: moduleFromId.sequence,
                            checked: moduleFromId.checked,
                            name: moduleFromId.name,
                        };
                    }
                });
            }
        });

        if (checked) {
            //check which modules require selection from Cascading rule
            Object.values(cascadingGroupsModules).forEach((mod) => {
                if (mod.moduleId !== module) {
                    if (
                        !mod.checked &&
                        mod.sequence < cascadingGroupsModules[module].sequence &&
                        !recursiveCheckArray.find((mod) => mod.id === module)
                    ) {
                        recursiveCheckArray.push(mod);
                        modulesForUpdate.modules.push({ id: mod.moduleId, checked: true });
                        modulesForToast.push(mod.name);
                        isCascading = true;
                    }
                }
            });
            if (modulesForUpdate.modules.length > 0 && isCascading) {
                cascadingAutoselected.lastChecked = module;
                cascadingAutoselected.autoselected = [...modulesForUpdate.modules];
            }

            //handle the dependencyChoice rule
            Object.values(dependencyChoiceGroups.current).forEach((grp) => {
                if (grp.primaryModuleId === module) {
                    const skipPopup = grp.modules.find((mod) => mod.checked) || false;
                    if (grp.modules.length > 0 && !skipPopup) {
                        grp.modules.forEach((grpMod) => {
                            if (
                                !recursiveCheckArray.find(
                                    (mod) => mod.moduleId === grpMod.moduleId
                                ) &&
                                grpMod.moduleId !== module
                            ) {
                                recursiveCheckArray.push(grpMod);
                            }
                        });
                        groupsForPopup.push({
                            activeModuleId: module,
                            activeModuleName: activeModName,
                            dependentModules: [...grp.modules],
                            isRecursiveCheck: true,
                        });
                    }
                }
            });

            if (recursiveCheckArray.length > 0) {
                recursiveCheckArray.forEach((mod) => {
                    recursiveModuleGroupCheck(
                        mod,
                        modulesForUpdate.modules,
                        modulesForToast,
                        groupsForPopup,
                        cascadingAutoselected
                    );
                });
            }
            if (isCascading) {
                setCascadingSelection({
                    lastChecked: cascadingAutoselected.lastChecked,
                    autoselected: [...cascadingAutoselected.autoselected],
                });
            }
            modulesForUpdate.modules.push({ id: module, checked: checked });
            if (modulesForToast.length > 0) {
                //adding only cascadingSelection modules to the toast, dependencyChoice ones are handled in the popup
                let message = appMessages.cascadingSelectionMessages(
                    'select',
                    modulesForToast,
                    cascadingGroupsModules[module].name
                );
                toasterMessage.push(message);
            }
            if (groupsForPopup.length === 0) {
                if (Object.keys(selectable).length === 0) {
                    //do not update if in the middle of recursion, only for modules in a rule that don't require other selection
                    dispatch(updateMultipleModuleSelection(modulesForUpdate));
                }
            } else {
                setSelectable(modulesForUpdate);
                setDependencyGroupsList(groupsForPopup);
            }
        } else if (!checked) {
            console.log(cascadingSelection, 'existing selection');
            //checks for cascadingSelection
            if (
                cascadingSelection.lastChecked === module &&
                activeModule === module
            ) {
                cascadingSelection.autoselected.forEach((mod) => {
                    modulesForUpdate.modules.push({ id: mod.id, checked: false });
                    modulesForToast.push(modules[mod.id].name);
                });
                if (modulesForUpdate.modules.length > 0) {
                    let message = appMessages.cascadingSelectionMessages(
                        'undo',
                        modulesForToast,
                        cascadingGroupsModules[module].name
                    );
                    toasterMessage.push(message);
                }
            } else {
                Object.values(cascadingGroupsModules).forEach((mod) => {
                    if (
                        mod.sequence > cascadingGroupsModules[module].sequence &&
                        mod.checked &&
                        !modulesForToast.includes(mod.name)
                    ) {
                        recursiveCheckArray.push(mod);
                        modulesForUpdate.modules.push({ id: mod.moduleId, checked: false });
                        modulesForToast.push(mod.name);
                    }
                });
            }

            //checks for DependencyChoice
            Object.values(dependencyChoiceGroups.current).forEach((grp) => {
                if (grp.moduleIds.includes(module) && grp.primaryModuleId !== module) {
                    const primaryModule = grp.modules.find(
                        (mod) => mod.moduleId === grp.primaryModuleId
                    );
                    const isPrimaryChecked = primaryModule.checked;
                    const isSecondaryChecked = grp.modules.find(
                        (mod) =>
                            mod.moduleId !== grp.primaryModuleId &&
                            mod.moduleId !== module &&
                            mod.checked === true
                    )?.checked;
                    const primaryModuleName = primaryModule.name;

                    if (isPrimaryChecked && !isSecondaryChecked) {
                        if (!modulesForToast.includes(primaryModuleName)) {
                            modulesForToast.push(primaryModuleName);
                        }
                        if (
                            !recursiveCheckArray.find(
                                (mod) => mod.moduleId === primaryModule.moduleId
                            )
                        ) {
                            recursiveCheckArray.push(primaryModule);
                            modulesForUpdate.modules.push({
                                id: primaryModule.moduleId,
                                checked: false,
                            });
                        }
                    }
                }
            });

            if (recursiveCheckArray.length > 0) {
                recursiveCheckArray.forEach((mod) => {
                    recursiveModuleGroupUncheck(
                        mod,
                        modulesForUpdate.modules,
                        modulesForToast
                    );
                });
            }

            modulesForUpdate.modules.push({ id: module, checked: checked });
            if (modulesForToast.length > 0) {
                let message = appMessages.cascadingSelectionMessages(
                    'undo',
                    modulesForToast,
                    cascadingGroupsModules[module].name
                );
                toasterMessage.push(message);
            }
            dispatch(updateMultipleModuleSelection(modulesForUpdate));
        }

        return [toasterMessage, groupsForPopup];
    };      
  
    const handleCheck = (id, checked) => {
        if (modules[id].groupModules.length > 0) {
            let cascadingFlag = false;
            const toastMessages = [];
            let popupArr = [];
            modules[id].groupModules.forEach((group) => {
                let message = [];
                switch (group.group.rule.name) {
                    case 'dependency':
                        message = handleDependency(group.groupId, checked);
                        break;
                    case 'exclusive':
                        message = handleExclusive(group.groupId, id, checked);
                        break;
                    case 'mandatoryChoice':
                        message = handleMandatory(group.groupId, id, checked);
                        break;
                    case 'mandatoryChoiceOncePerYear':
                    case 'mandatoryChoiceFirstWave':
                        message = handleMandatoryFirstWaveOrOncePerYear(
                            group.groupId,
                            id,
                            checked
                        );
                        break;
                    case 'cascadingSelection':
                    case 'DependencyChoice':
                        if (
                            (group.group.rule.name === 'cascadingSelection' &&
                                !cascadingFlag) ||
                            group.group.rule.name === 'DependencyChoice'
                        ) {
                            [message, popupArr] = handleComboGroupCheck(id, checked);
                            if (
                                group.group.rule.name === 'cascadingSelection' &&
                                !cascadingFlag
                            ) {
                                cascadingFlag = true;
                            }
                        }
                        break;
                    case 'mandatoryonceperyear':
                    case 'mandatoryfirstwave':
                        dispatch(updateModuleSelection(id, checked));
                        break;
                    default:
                        console.log('unhandled rule');
                }

                //if popup should be shown, set it in state, otherwise continue with toast message
                if (popupArr.length > 0) {
                    setDependencyGroupsList([...popupArr]);
                } else {
                    updateToastMessageArray(toastMessages, message);
                }
            });

            validateAndShowToast(toastMessages);
        } else {
            dispatch(updateModuleSelection(id, checked));
        }
    };


    
  /**
   * @function
   * @memberof SidePanel
   * @param {Object} toastMessages Array of message objects.
   * @param {Object} messageObj Object with properties title, message.
   * @description If messageObj is valid object. This method adds messageObj to toastMessages array.
   */
  const updateToastMessageArray = (toastMessages, messageArr) => {
    if (messageArr.length > 0) {
      messageArr.forEach((msg) => {
        let message = msg.message;
        if (!toastMessages.find((obj) => obj.message === message)) {
          toastMessages.push(msg);
        }
      });
    }
    
  };
  /**
   * @function
   * @memberof SidePanel
   * @param {Object} toastMessages Array of message objects.
   * @description Method to validate and show toast message. If toastMessages array length is 1, Show title from the first element of toastMessages array, if toastMessages array length is more than 1, Show generic title.
   */
  const validateAndShowToast = (toastMessages) => {
    if (toastMessages.length === 1) {
      const finalMessage = toastMessages[0];
      showToaster(finalMessage, true);
    } else if (toastMessages.length > 1) {
      const toastMessageObj = arrayToMessageObject(toastMessages);
      const errorMessages = appMessages.stackMessages(toastMessageObj);
      const finalMessage = toasterContent(errorMessages);
      showToaster(finalMessage, true);
    }
  };
  /**
   * @function
   * @memberof SidePanel
   * @param {Object} arr Array of message objects.
   * @description Method to convert array to object. It creates object key as index + 1 on each array iteration.
   */
  const arrayToMessageObject = (arr) => {
    return arr.reduce((obj, element, index) => {
      return {
        ...obj,
        [index + 1]: element.message,
      };
    }, {});
  };
  /**
   * @function
   * @memberof SidePanel
   * @param {Number} group Id of the group that the function is called on.
   * @param {Boolea} checked
   * @returns {Object} toasterMessage as empty object or object with keys title & message for toast error.
   * @description The function takes effect on the entire group. When one module in the group is checked/unchecked, the same update will be performed on all modules in the group, and 'updateMultipleModuleSelection' will be dispatched to reflect the changes in the store as well.
   */
  const handleDependency = (group, checked) => {
    const result = { modules: [] };
    const toasterMessage = [];
    groups[group].moduleIds.forEach((moduleId) => {
      result.modules.push({ id: moduleId, checked: checked });
    });
    dispatch(updateMultipleModuleSelection(result));
    return toasterMessage;
  };

  /**
   * @function
   * @memberof SidePanel
   * @param {Number} group Id of the group.
   * @param {Number} id Id of the module that the function is called on.
   * @param {Boolean} checked
   * @returns {Object} toasterMessage as empty object or object with keys title & message for toast error.
   * @description When one module in the group is checked all other modules in the group will be unchecked, and 'updateMultipleModuleSelection' will be dispatched to reflect the changes in the store as well.
   */
  const handleExclusive = (group, id, checked) => {
    const toasterMessage = [];
    const result = { modules: [] };
    groups[group].moduleIds.forEach((moduleId) => {
      if (moduleId === id) {
        result.modules.push({ id: moduleId, checked: checked });
      } else {
        result.modules.push({ id: moduleId, checked: false });
      }
    });
    dispatch(updateMultipleModuleSelection(result));
    return toasterMessage;
  };

  /**
   * @function
   * @memberof SidePanel
   * @param {Number} group Id of the group.
   * @param {Number} id Id of the module that the function is called on.
   * @param {Boolean} checked
   * @returns {Object} toasterMessage as empty object or object with keys title & message for toast error.
   * @description The user has to select at least one of the mandatory group modules or their module selection will not be valid ( result of 'updateGroupValidCheck' ), and an icon will be shown at the top of the group informing the user that they need to select at least one module. When the user modifies their selection for the group 'updateMultipleModuleSelection' will be dispatched to reflect the changes in the store as well.
   */
  const handleMandatory = (group, id, checked) => {
    const toasterMessage = [];
    let flag = false;
    if (checked) {
      flag = true;
    } else {
      groups[group].moduleIds.forEach((module) => {
        if (modules[module].selected && id !== module) {
          flag = true;
        }
      });
    }

    dispatch(updateModuleSelection(id, checked));
    dispatch(updateGroupValidCheck(group, flag));
    return toasterMessage;
  };

  /**
   * @function
   * @memberof SidePanel
   * @param {Number} group Id of the group.
   * @param {Number} id Id of the module that the function is called on.
   * @param {Boolean} checked
   * @returns {Object} toasterMessage as empty object or object with keys title & message for toast error.
   * @description The user has to select at least one of the mandatory group modules or their module selection will not be valid ( result of 'updateGroupValidCheck' ), and an icon will be shown at the top of the group informing the user that they need to select at least one module. When the user modifies their selection for the group 'updateMultipleModuleSelection' will be dispatched to reflect the changes in the store as well.
   */
  const handleMandatoryFirstWaveOrOncePerYear = (group, id, checked) => {
    let toasterMessage = [];
    let flag = false;

    if (isMandatoryModuleGroupForThisWave(id)) {
      toasterMessage = handleMandatory(group, id, checked);
    } else {
      flag = true;
      dispatch(updateModuleSelection(id, checked));
      dispatch(updateGroupValidCheck(group, flag));
    }
    return toasterMessage;
  };
  const isMandatoryModuleGroupForThisWave = (moduleId) => {
    const groupOfModule = Object.values(groups).find((group) =>
      group.moduleIds.includes(moduleId)
    );

    if (!groupOfModule) return true;

    return !(
      groupOfModule.mandatoryGroupRuleFirstWave ||
      groupOfModule.mandatoryGroupRuleOncePerYear
    );
  };

  /**
   * @function
   * @memberof SidePanel
   * @param {Number} id Id of the module that was clicked
   *
   * @description When the function is called on a module it updates the contents of the 'ModuleContent' component to render the module contents in it, and the store is updated using 'updateActiveModule(id)' to reflect the fact that the the module on which the function was called is the module that's currently active.
   */
  const handleClick = (id) => {
    updateActiveModule(id);
    clickHandler('module', id);
  };

  /**
   * @function
   * @memberof SidePanel
   *
   * @description The function is called when the user wants to save their module selection, and it branches into two paths:
   *  - If the user's uxPermissions allow them to overwrite the length of interview limit, then 'saveModuleSelection(modules, waveId)' is dispatched, and their module selection is saved.
   *  - If the user  can't overwrite the length of interview limit, then 'validateModuleSelection' is called, to generate a report on the validity of the module selection. If the report is valid 'saveModuleSelection(modules, waveId)' is dispatched, if not a toaster is shown displaying the issues encountered when validating their module selection.
   */

  const saveSelection = () => {
    const report = validateModuleSelection();
    const message = toasterContent(report.errorMessages);

    if (canOverwriteLoiLimit.current) {
      if (report.errorMessages.includes('Mandatory module not selected')) {
        // ensures module selection can't be saved even if the user is admin if a mandatory module has not been selected.
        showToaster(message, true);
        setMandatoryIconErr(true);
      } else {
        dispatch(saveModuleSelection(modules, waveId));
        setMandatoryIconErr(false);
      }
    } else {
      setSavedModuleSelection(true);
      setMandatoryIconErr(true);

      if (report.valid) {
        dispatch(saveModuleSelection(modules, waveId));
      } else {
        showToaster(message, true);
      }
    }
  };

  /**
   * @function
   * @memberof SidePanel
   * @param {String} toastmessage String that will be displayed as the content of the toast message.
   *
   * @returns {Object} The function returns an object with the following keys: "title", and ( with the value being "Module selection validation" ), and "message" ( with the value being the value of the "toastMessage" argument ).
   */
  const toasterContent = (toastmessage) => {
    return { title: 'Module selection validation', message: toastmessage };
  };

  /**
   * @function
   * @memberof SidePanel
   *
   * @description The function performs the following checks:
   *
   * - Length of Interview: the 'validateLOIMinutes' function is called to check if the module selection exceeds the maximum length of interview. If this is the case the 'errorMessages' object is updated to reflect that the total length of interview from the modules ( and local questions ) the user selected exceeded the limit.
   * - Mandatory Choice: the function checks all groups with this rule, and if the group isn't valid ( which is the case when the user doesn't select any modules in the group ) a toast message is shown, and the 'errorMessages' object is updated to reflect that the user needs to select at least one module in the group.
   *
   * Once both checks are performed, if no issues are found the object that is returned wil have its 'valid' key set to true ( meaning that the user's module selection is valid ), and the 'errorMessages' key will be an empty string. If errors were encountered the 'valid' key on the returned object will be false, and the 'errorMessages' key will contain key-value pairs of the errors and error messages corrensponding to the issues found.
   *
   * @returns {Object} The function returns an object containing the following keys: 'valid' ( Boolean, true if no issues were found, false otherwise), and 'errorMessages' ( Object containing all error messages corresponding to the isseus found ).
   *
   */
  const validateModuleSelection = () => {
    const errorMessages = {};
    const loiErrorMessage = validateLOIMinutes();
    if (loiErrorMessage) {
      errorMessages['loiError'] = loiErrorMessage;
    }

    Object.values(groups).forEach((group) => {
      if (checkMandatoryChoiceGroup(group) && !group.valid) {
        ref.current[group.groupId]?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        const moduleNames = [];
        group.moduleIds.forEach((moduleId) => {
          moduleNames.push(modules[moduleId].name);
        });
        const message = appMessages.setModuleMandatoryMessage(
          'toast',
          moduleNames
        );
        errorMessages[group.groupId] = message;
      }
    });

    const report = { valid: true, errorMessages: '' };

    if (Object.values(errorMessages).length > 0) {
      report.valid = false;
      report.errorMessages = appMessages.stackMessages(errorMessages);
      return report;
    } else {
      return report;
    }
  };

  /**
   * @function
   * @memberof SidePanel
   *
   * @description The function checks if the module selection exceeds the maximum allowed length of interview.
   * @returns {String} If the user's selection exceeds the maximum length of interview ( which is calculated as the sum of each selected module's length, and the length of all custom questions that the user added ) a string stating that this is the case is returned, other wise the function returns null.
   */
  const validateLOIMinutes = () => {
    const totalLoi = calcloi(modules, customQuestions);
    if (totalLoi > maxLoi) {
      return appMessages.LOImessage(maxLoi);
    }
    return null;
  };

  /**
   * @function
   * @memberof SidePanel
   * @param {Number} cnt The number of local questions that were added to this module selection process.
   *
   * @description If the module selection contains less than 3 custom questions, and 'isLoadingCustQ' (whose initial value is taken from the store ) is false. If adding the local question would exceed the length of interview an error toaster is shown, stating that the maximum length of interview would be exceeded if the local question were added. if not it sets 'isLoadingCustQ' to true, and then it dispatches'CreateCustomQuestion', creating a new local question for this wave.
   */
  const addCustomQuestion = (cnt) => {
    if (cnt <= 3 && !isLoadingCustQ) {
      if (CanCustomQuestionBeAdded()) {
        setIsLoadingCustQ(true);
        dispatch(
          CreateCustomQuestion(
            waveId,
            { name: '', customNamePrefix: GetCustomNamePrefix() },
            wave.waveLanguages
          )
        );
      } else {
        showToaster({
          title: messages?.createLocalQuestionError?.title,
          message: getMessageWithReplacedKeyValue(
            messages?.createLocalQuestionError?.text,
            'maxLoi',
            maxLoi
          ),
        });
      }
    }
  };

  /**
   * @function
   * @memberof SidePanel
   * @param {Object} group Object corresponding to the group. It contains the following keys: 'groupId', 'countryGroupRules' ( array of rules that apply at a country level ), 'mandatoryFirstWave', 'mandatoryOncePerYear', 'name' ( name of the group in the database ), rule ( object containing the 'ruleId' and 'name' of the rule ), and 'ruleId.
   * @param {Object} res Object passed down by the function in which 'validateCountryGroupRuleMandatoryOncePerYear' is called, and which will be updated based on the 'group' argument.
   *
   * @description The function checks the 'group' object to see if the group is mandatory once per year in the last wave of the year, or if it's mandatory once per year in the first wave of the year.
   *
   * @returns {Object} Object containing the properties below:
   *
   *  **mandatoryGroupRuleOncePerYear** - Boolean - true because this function is called on a group that has the mandatoryOncePerYear country group rule.
   *  **mandatoryGroupThisWave** - Boolean - true if the user has to select a module that is part of this group in this wave.
   *  **mandatoryGroupRuleFirstWave** - Boolean - true if the user has to select a module that is part of this group in the first wave of the year.
   *  **valid** - Boolean - false
   */
  const validateCountryGroupRuleMandatoryOncePerYear = (group, res) => {
    if (group.mandatoryOncePerYear) {
      res.mandatoryGroupRuleOncePerYear = true;
      res.mandatoryGroupThisWave = true;
      res.valid = false;
    } else if (group?.rule.name === 'mandatoryChoiceOncePerYear') {
      res.mandatoryGroupRuleOncePerYear = true;
    }
    if (group.mandatoryFirstWave) {
      res.mandatoryGroupRuleFirstWave = true;
      res.mandatoryGroupThisWave = true;
      res.valid = false;
    }
    return res;
  };

  /**
   * @function
   * @memberof SidePanel
   * @param {Object} groupData Object containing all data corresponding to this group.
   * @param {Object} mandatoryChoiceGroup Object containing the keys: 'groupId', 'moduleIds' ( array of moduleIds that are part of this group ), 'rule', and 'valid'.
   * @param {Boolean} savedModuleSelection Boolean for if the user saved their module selection for this wave.
   *
   * @description The function checks the 'mandatoryChoiceGroup' argument and creates an array 'moduleNames' that contains the names of all modules that are part of this group rule. Then the function calls 'handleMandatoryMessage(groupData,mandatoryChoiceGroup,savedModuleSelection, moduleNames)'
   *
   * @returns {Function} returns the function 'handleMandatoryMessage', which is called with the arguments that 'getMandatoryMessage' receives, as well as 'moduleNames' an array of the names of the modules that are part of the mandatory choice group.
   */
  const getMandatoryMessage = (
    groupData,
    mandatoryChoiceGroup,
    savedModuleSelection
  ) => {
    const moduleNames = [];
    mandatoryChoiceGroup?.moduleIds?.forEach((id) => {
      moduleNames.push(modules[id].name);
    });
    return handleMandatoryMessage(
      groupData,
      mandatoryChoiceGroup,
      savedModuleSelection,
      moduleNames
    );
  };

  /**
   * @function
   * @memberof SidePanel
   *
   * @description The function checks if adding a local question would exceed the maximum allowed length of interview ( by calculating the sum of the current length of interview and the custom question length of interview contribution and comparing it to the wave's 'maxLoi'). If it is exceeded it means the user can't add a custom question, and false will be returned, and if it is not exceeded the user can add a local question, and true will be returned.
   * @returns {Boolean} True if the new custom question can be added without exceeding the wave's 'maxLoi', false otherwise.
   */
  const CanCustomQuestionBeAdded = () => {
    const newCustomQLOI = customQuestionloiContributionInMinutes;
    const totalLoi = calcloi(modules, customQuestions);
    return newCustomQLOI + totalLoi <= maxLoi;
  };

  /**
   * @function
   * @memberof SidePanel
   * @param {Object} module Object containing all data corresponding to the module
   *
   * @description The function performs the following checks and updates the 'res' object it returns.
   * - **checkMandatoryChoiceGroup**: Method take param as group and return true if group.rule belongs to mandatoryChoice or mandatoryChoiceOncePerYear or mandatoryChoice rule, else false.
   *
   * - **Group Module Checks**: If 'module.groupModules' is not empty, it performs:
   *      - **First Module Check**: Sets 'res.first' to true if the module is first in its group and sequential. For 'mandatoryChoice' rule, also sets 'res.firstMandatory' to true and updates validity.
   *      - **Last Module Check**: Sets 'res.last' to true if the module is last in a sequential group. For 'mandatoryChoice' rule, sets 'res.lastMandatory' to true and updates validity.
   *      - **Exclusive Rule Check**: Sets 'res.exclusive' to true if the group rule is 'exclusive'.
   *  - **Country Module Rule Checks**: Evaluates rules in 'module.countryModuleRules':
   *      - If rule is 'mandatoryonceperyear', sets 'res.mandatoryOncePerYear' and 'res.firstMandatory' to true.
   *      - If rule is 'mandatoryfirstwave', sets 'res.mandatoryOncePerYear' and 'res.firstWaveMandatoryRule' to true.
   *  - **Mandatory Module Checks**: If the module is mandatory and the group rule is 'mandatoryonceperyear', sets 'res.mandatoryThisWave' to true. Shows a toaster message if conditions are met and 'mandatoryModuleName.current' is empty.
   *
   *  @returns {Object} The 'res' object returned contains the following keys:
   *
   * * **first** - Boolean - True or false, depending if the module is the first module in its group
   * * **last** - Boolean - True or false, depending if the module is the last module in its group
   * * **firstMandatory** - Boolean - True if the module in question is the first module in its group and the group rule is 'mandatoryChoice'
   * * **lastMandatory** - Boolean - True if the module is the last in its group and the group's rule is 'mandatoryChoice'.
   * * **exclusive** - Boolean - True of false depending on if the group rule is 'exclusive', meaning that only one module can be selected.
   * * **mandatoryOncePerYear** - Boolean - True if the user has to select the module at least once per year.
   * * **mandatoryThisWave** - Boolean - True if the user has to select the module this wave.
   * * **mandatoryGroupThisWave** - Boolean - True if the user has to select a module from the group this wave.
   * * **mandatoryGroupRuleOncePerYear** - Boolean - True if the user has to select at least once module out of the group in the last wave of the year.
   * * **mandatoryGroupRuleFirstWave** - Boolean - True if the user has to select at least once module out of the group in the first wave of the year.
   * * **firstWaveMandatoryRule** - Boolean - True if the module needs to be selected once in the first wave of the year.
   * * **valid** - Boolean - The value of the 'valid' property in the group out of which the module being checked is part.
   * * **groupIds** - Array - Ids of all groups the module being checked is part of
   * * **moduleId** - Number - Id of module being checked.
   */

    const checkGroups = (module) => {
        const res = {
            first: false,
            last: false,
            firstMandatory: false,
            lastMandatory: false,
            exclusive: false,
            mandatoryOncePerYear: false,
            mandatoryThisWave: false,
            mandatoryGroupThisWave: false,
            mandatoryGroupRuleOncePerYear: false,
            mandatoryGroupRuleFirstWave: false,
            valid: false,
            groupIds: [],
            moduleId: module.surveyModuleId,
            firstWaveMandatoryRule: false,
        };
        if (module.groupModules.length > 0) {
            module.groupModules.forEach((group) => {
                res.groupIds.push(group.groupId);
                const orderedGroup = order
                    .filter((item) => groups[group.groupId].moduleIds.includes(item))
                    .sort();
                let sequentialGroup = true;
                for (let i = 1; i < orderedGroup.length; i++) {
                    if (
                        order.indexOf(orderedGroup[i - 1]) !==
                        order.indexOf(orderedGroup[i]) - 1
                    ) {
                        sequentialGroup = false;
                        break;
                    }
                }
                //mandatory once per year - mandatory last wave
                if (groups[group.groupId].moduleIds.at(0) === module.surveyModuleId) {
                    if (sequentialGroup && groups[group.groupId]?.moduleIds?.length > 1) {
                        res.first = true;
                    }
                    if (checkMandatoryChoiceGroup(groups[group.groupId])) {
                        res.valid = groups[group.groupId].valid;
                        res.firstMandatory = true;
                        validateCountryGroupRuleMandatoryOncePerYear(group.group, res);
                    }
                } else if (
                    groups[group.groupId].moduleIds.at(-1) === module.surveyModuleId
                ) {
                    if (sequentialGroup && groups[group.groupId]?.moduleIds?.length > 1) {
                        res.last = true;
                    }
                    if (checkMandatoryChoiceGroup(groups[group.groupId])) {
                        res.valid = groups[group.groupId].valid;
                        res.lastMandatory = true;
                    }
                }
                //exclusive
                if (group.group.rule.name === 'exclusive') {
                    res.exclusive = true;
                }

                if (group.group.rule.name === 'mandatoryonceperyear') {
                    res.mandatoryOncePerYear = true;
                    res.firstMandatory = true;
                }
                if (group.group.rule.name === 'mandatoryfirstwave') {
                    res.mandatoryOncePerYear = true;
                    res.firstWaveMandatoryRule = true;
                }
            });
        }
        if (module.mandatory && res.mandatoryOncePerYear) {
            res.mandatoryThisWave = true;
            if (mandatoryModuleName.current === '') {
                mandatoryModuleName.current = module.name;
                let toastTitle = '';
                let toastMsg = '';

                if (res.firstWaveMandatoryRule) {
                    toastTitle = messages?.mandatoryFirstWaveRuleThisWave?.title;
                    toastMsg = getMessageWithReplacedKeyValue(
                        messages?.mandatoryFirstWaveRuleThisWave?.text,
                        'mandatoryModuleName',
                        mandatoryModuleName.current
                    );
                } else {
                    toastTitle = messages?.mandatoryOncePerYearThisWave?.title;
                    toastMsg = getMessageWithReplacedKeyValue(
                        messages?.mandatoryOncePerYearThisWave?.text,
                        'mandatoryModuleName',
                        mandatoryModuleName.current
                    );
                }

                showToaster(
                    {
                        title: toastTitle,
                        message: toastMsg,
                    },
                    true
                );
            }
        }

        return res;
    };      

  const handleHideModal = (id) => {
    setDependencyChoiceCurrentGroup({
      activeModuleId: null,
      activeModuleName: '',
      dependentModules: [],
      isRecursiveCheck: false,
    });
    setDependencyGroupsList([]);
    if (Object.keys(selectable).length > 0) {
      selectable.modules.forEach((module) => {
        dispatch(updateModuleSelection(module.id, false));
      });
    }
    setSelectable({}); //resetting selection when popup is cancelled/closed
    setForceRerender((prevState) => prevState + 1);
  };

  const handleSaveModalSelection = (selection) => {
    setDependencyChoiceCurrentGroup({
      activeModuleId: null,
      activeModuleName: '',
      dependentModules: [],
      isRecursiveCheck: false,
    });
    const payload = { modules: [] };
    selection.forEach((item) =>
      payload.modules.push({ id: item, checked: true })
    );
    dispatch(updateMultipleModuleSelection(payload));
  };

  const maxLoiModalContent = (
    <div className={styles.loiModalContainer}>
      <p>
        {getMessageWithReplacedKeyValue(
          messages?.maxLoiModalContentErrorMessage?.text,
          'maxLoi',
          maxLoi
        )}
      </p>
      <div className={styles.loiButtonsContainer}>
        <Button
          disabled={false}
          type={'primary'}
          handleOnClick={() => {
            saveSelection();
            setShowMaxLoiModal(false);
          }}
        >
          Yes
        </Button>
        <Button
          disabled={false}
          type={'primary'}
          handleOnClick={() => setShowMaxLoiModal(false)}
        >
          No
        </Button>
      </div>
    </div>
  );

  /**
@method useEffect - 1st instance  
   * @memberof SidePanel
   *
   * @description When the component mounts the effect dispatches 'GetCustomQuestions(waveId)' and 'GetWave(waveId)' to get the required data to render the contents of the module selection.
   */
  useEffect(() => {
    dispatch(GetCustomQuestions(waveId));
    dispatch(GetWave(waveId));
  }, [dispatch,waveId]);

  /**
@method useEffect - 2nd instance  
   * @memberof SidePanel
   *
   * @description The effect updates the 'isLoadingCustQ' state variable to the value of 'loadingCustQ', which is taken from the state.
   */
  useEffect(() => {
    setIsLoadingCustQ(loadingCustQ);
  }, [loadingCustQ]);

  /**
@method useEffect - 3rd instance  
   * @memberof SidePanel
   *
   * @description The effect calls 'checkGroups(module)' for all modules, and then it checks if the moduleGroupData is empty. If this is the case, it updates 'moduleGroupData' to the appropriate value.
   *
   * The effect is triggered every time 'modules' is updated in the store.
   */
  useEffect(() => {
      const groupData = {};
      Object.values(modules).forEach((module) => {
      groupData[module.surveyModuleId] = checkGroups(module);
    });
    const sameData =
      JSON.stringify(groupData) === JSON.stringify(moduleGroupData);

    if (!sameData && Object.keys(moduleGroupData).length === 0) {
      setModuleGroupData({ ...groupData });
    }
  }, [modules]);

  /**
@method useEffect - 4th instance  
   * @memberof SidePanel
   *
   * @description The effect checks if a group is mandatory this wave, and if this is the case it creates a 'payload'. Each module of the group that is mandatory this wave is added in the payload as an object. The object corresponding to the module contains the following keys: "id" ( the id of the module ), and "checked" ( the checked status of the module ).
   *
   * After creating the payload the function dispatches 'updateMultipleModuleSelection' with the payload as argument to update the module selection in the store as well.
   * This effect is triggered every time 'moduleGroupData' is updated.
   */
  useEffect(() => {
    if (Object.keys(moduleGroupData).length > 0) {
      const payload = { modules: [] };
      Object.values(moduleGroupData).forEach((module) => {
        if (module.mandatoryThisWave) {
          return payload.modules.push({
            id: module.moduleId,
            checked: moduleGroupData[module.moduleId].mandatoryThisWave,
          });
        }
      });
      payload.changesToSave = false;
      dispatch(updateMultipleModuleSelection(payload));
    }
  }, [moduleGroupData,dispatch]);

  /**
@method useEffect - 5th instance  
   * @memberof SidePanel
   *
   * @description Once 'wave' is available in the store, and it is not falsy, the function dispatches 'fetchModulesList' to get the modules list for this wave.
   */
  useEffect(() => {
    if (!countryId) return;
    if (modules.modules && Object.keys(modules.modules).length > 0) return;
    dispatch(
      fetchModulesList(projectId, waveId, countryId, isBoosterWave(wave))
    );
  }, [countryId,dispatch,modules.modules,projectId,wave,waveId]);

  /**
@method useEffect - 6th instance  
   * @memberof SidePanel
   *
   * @description The function checks if there are any groups in this wave that have the 'cascadingSelection' group rule. If this is the case a new object is created containing all groups that have this rule. Each module part of a group with the 'cascadingSelection' group will contain the following properties: 'name', 'moduleId' 'seuqnce' , and 'checked'. After this the value of 'cascadingSelectionGroups.current' will be updated to that of the object that was created inside of the effect.
   *
   * The effect is triggered every time the value of 'groups' and 'modules' updates.
   */
  useEffect(() => {
    if (Object.keys(modules).length > 0) {
      const newGroups = Object.values(groups)
        .filter((groupObj) => groupObj.rule === 'cascadingSelection')
        .map((groupObj) => {
          const newGroup = { ...groupObj, modules: [] };
          if (!groupObj.moduleIds) return null;
          newGroup.moduleIds.forEach((moduleId) => {
            newGroup.modules.push({
              name: modules[moduleId].name,
              moduleId,
              sequence: modules[moduleId]?.sequence,
              checked: modules[moduleId].selected,
            });
          });
          return newGroup;
        })
        .filter((group) => group !== null);

      cascadingSelectionGroups.current = newGroups;
      cascadingSelectionGroups.rule = 'cascadingSelection';

      
      const groupsObj = Object.values(groups)
        .filter((grpObj) => grpObj.rule === 'DependencyChoice')
        .map((grpObj) => {
          const newGroupsObj = {
            ...grpObj,
            modules: [],
            primaryModuleId: null,
          };
          grpObj.moduleIds.forEach((moduleId) => {
            if (
              newGroupsObj.primaryModuleId === null &&
              modules[moduleId].groupModules.find(
                (group) => group.groupId === grpObj.groupId
              ).primaryModule === true
            ) {
              newGroupsObj.primaryModuleId = moduleId;
            }

            newGroupsObj.modules.push({
              name: modules[moduleId].name,
              moduleId,
              sequence: modules[moduleId]?.sequence,
              checked: modules[moduleId].selected,
            });
          });
          return newGroupsObj;
        });

      dependencyChoiceGroups.current = groupsObj;
      dependencyChoiceGroups.rule = 'DependencyChoice';
    }
  }, [groups, modules]);

  /**
@method useEffect - 7th instance  
   * @memberof SidePanel
   *
   * @description Once the component mounts and 'uxPrivileges' is taken from state, the effect calls 'checkUXPrivileges' to see if the user's ux privileges allow them to overwrite the Loi limit, and updates the value of 'canOverwriteLoiLimit.current' to the result of the function.
   */
  useEffect(() => {
    canOverwriteLoiLimit.current = checkUXPrivileges(
      uxPrivileges,
      'OverwriteLoiLimit'
    );
  }, [uxPrivileges]);
  /**
@method useEffect - 8th instance  
   * @memberof SidePanel
   *
   * @description On 'questionType' click(modules/local questions section) on side panel page, this useEffect hook renders the last active module/local question details.
   */
  useEffect(() => {
    selectedPageTypeHandler(questionType === '1');
    if (questionType === '1' && activeModule > 0) {
      handleClick(activeModule);
    } else if (questionType === '2' && focusedQuestion > 0) {
      setCurrentCustomQuestion(focusedQuestion);
    } else {
      clickHandler('blankPage');
    }
  }, [questionType]);
  const showAddLocalQuestionButton = () => {
      return checkUXPrivileges(uxPrivileges, 'submitCustomQ')
          && checkModulesAndCustomQuestionLoaded()
          && maxCustomQ > Object.keys(customQuestions).length
          && !isReadOnly
          && maxLoi
          && ISOCode 
    }
    const checkModulesAndCustomQuestionLoaded = () => {
        return !loading && !loadingCustQ;
    }
  return (
    <div data-testid="sidePanel" className={styles.PanelContainer}>
      {showMaxLoiModal && (
        <ModalDialog show={showMaxLoiModal} content={maxLoiModalContent} />
      )}
      <Logo />
      <div className={styles.QuestionSelectorPos}>
        <QuestionTypeSelection
          questionTypes={[
            {
              id: '1',
              name: 'Modules',
            },
            {
              id: '2',
              name: 'Local questions',
            },
          ]}
          getClicked={(id) => setQuestionType(id)}
          activeBtnId={questionType}
        ></QuestionTypeSelection>
      </div>
      {questionType === '1' ? (
        <div className={styles.ModuleBlock}>
          <ModalDialog
            show={
              dependencyChoiceCurrentGroup.activeModuleId === activeModule ||
              dependencyChoiceCurrentGroup.isRecursiveCheck
            }
            content={
              <ModuleGroupPopup
                groupModules={dependencyChoiceCurrentGroup}
                handleClose={(id) => handleHideModal(id)}
                handleSave={(selection) => handleSaveModalSelection(selection)}
                handleRecursiveCheck={(selectedModules) =>
                  handleUserInputRecursiveCheck(selectedModules)
                }
              />
            }
          />
          <div>
            {loading || Object.keys(moduleGroupData).length === 0
              ? 'Loading'
              : Object.values(modules)
                  .sort((a, b) => a.sequence - b.sequence)
                  .map((module, ind) => {
                    const groupData = moduleGroupData[module.surveyModuleId];
                    
                    let mandatoryGrpId = -1;
                    groupData?.groupIds.forEach((grpid) => {
                      if (checkMandatoryChoiceGroup(groups[grpid])) {
                        mandatoryGrpId = grpid;
                      }
                    });
                    return [
                      groupData.first ? (
                        <div
                          ref={(el) => (ref.current[mandatoryGrpId] = el)}
                          key={module.surveyModuleId + 'x'}
                          className={`${styles.Border} ${
                            styles[
                              setBorderStyle(
                                handleMandatoryIcon(
                                  groupData,
                                  mandatoryChoiceGroup,
                                  mandatoryIconErr,
                                  savedModuleSelection
                                ),
                                groupData.firstMandatory
                              )
                            ]
                          }`}
                        ></div>
                      ) : null,
                      shouldShowTooltip(
                        wave,
                        groupData,
                        mandatoryChoiceGroup
                      ) ? (
                        <Tooltip
                          content={getMandatoryMessage(
                            groupData,
                            mandatoryChoiceGroup,
                            mandatoryIconErr
                          )}
                          icon={handleMandatoryIcon(
                            groupData,
                            mandatoryChoiceGroup,
                            mandatoryIconErr,
                            savedModuleSelection
                          )}
                        />
                      ) : null,
                      <Checkbox
                        key={module.surveyModuleId}
                        label={module.name}
                        mandatory={module.mandatory}
                        id={module.surveyModuleId}
                        active={module.surveyModuleId === activeModule}
                        checked={groupData.mandatoryThisWave || module.selected}
                        changed={(checked, id) => handleCheck(id, checked)}
                        clicked={(id) => handleClick(id)}
                        radio={groupData.exclusive}
                        disabled={isReadOnly}
                        forceRerender={forceRerender || 0}
                      ></Checkbox>,
                      groupData.last ? (
                        <div
                          key={module.surveyModuleId + 'z'}
                          className={`${styles.Border} ${styles.Last} ${
                            styles[
                              setBorderStyle(
                                handleMandatoryIcon(
                                  groupData,
                                  mandatoryChoiceGroup,
                                  mandatoryIconErr,
                                  saveModuleSelection
                                ),
                                groupData.lastMandatory
                              )
                            ]
                          }`}
                        ></div>
                      ) : null,
                    ];
                  })}
          </div>
          <div className={styles.SaveBtnContainer}></div>
        </div>
      ) : null}
      {questionType === '2' ? (
        <div className={styles.localQuestionsContainer}>
          {loadingCustQ
            ? 'loading'
            : Object.values(customQuestions).map((question, ind) => {
                return (
                  <ComplexButton
                    key={module.surveyModuleId}
                    id={question.customQuestionId}
                    iconType={'delete'}
                    disableSecondaryBtn={
                      (question.customQuestionStatus.status === 'New' ||
                        question.customQuestionStatus.status === 'Requested') &&
                      moduleSelectionStatus !== 'Finished'
                        ? false
                        : true
                    }
                    handleOnClick={(id) => setCurrentCustomQuestion(id)}
                    handleOnClickIcon={(id) => handleDeleteCustomQuestion(id)}
                    focusedQ={focusedQuestion}
                  >
                    {ind + 1}.{' '}
                    {GetFormattedLocalQuestionName(
                      question?.customNamePrefix,
                      question?.question?.name
                    )}
                  </ComplexButton>
                );
              })}
          {showAddLocalQuestionButton()? (
            <Button
              type="default"
              handleOnClick={() =>
                addCustomQuestion(Object.keys(customQuestions).length + 1)
              }
            >
              + Add local question
            </Button>
          ) : null}
        </div>
      ) : null}
      {checkModulesAndCustomQuestionLoaded() ? (
        <div className={styles.BottomContainer}>
          <LengthOfInterview
            number={calcloi(modules, customQuestions)}
            threshold={maxLoi}
          >
            {['Length of interview: ', ' minutes']}
          </LengthOfInterview>
          {questionType === '1' && (
            <Button
              type="primary"
              handleOnClick={() => {
                if (
                  canOverwriteLoiLimit.current &&
                  calcloi(modules, customQuestions) > maxLoi
                ) {
                  setShowMaxLoiModal(true);
                } else {
                  saveSelection();
                }
              }}
              disabled={!changesToSave}
            >
              Save
            </Button>
          )}
        </div>
      ) : (
        'loading'
      )}
    </div>
  );
};

export default SidePanel;



















